import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline"},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Need a document scanner? "),_c(VSpacer),_c(VIcon,{staticClass:"cursor-pointer",attrs:{"color":"red"},on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._t("scanIcon",[_vm._v("mdi-scan-helper")])],2),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c(VIcon,[_vm._v(_vm._s(_vm.show ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c(VSlideYTransition,[(_vm.show)?_c(VCardText,[_c('p',[_vm._v(" Use your smartphone to scan any document, like a "+_vm._s(_vm.doc)+", save it on your computer or email it to yourself, and then you can store it in your secure Peeps File archive. ")]),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('div',[_c('a',{attrs:{"href":"https://swiftscan.app","target":"_blank"}},[_c(VImg,{staticClass:"cursor-pointer",attrs:{"max-width":"50","src":require("@/assets/img/SwiftScan.png")}})],1)]),_c('div',{staticStyle:{"max-width":"200px","margin-left":"1rem"}},[_vm._v(" Click this scanner icon to learn more about this scanning app ")])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c(VImg,{attrs:{"src":require("@/assets/img/PeepsBeside.svg"),"max-width":"35"}})],1),_c('div',[_c('p',{staticClass:"peeps--font ml-1"},[_vm._v(" Note: there are other scanning apps and alternatives on the market, but my technical team has had good success with this app, and thought sharing that with you might be helpful. ")])])])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }